// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDLjMFniV05MC-B2BmwFlVALmZzWlsQwmc",
    authDomain: "capetown-cats.firebaseapp.com",
    projectId: "capetown-cats",
    storageBucket: "capetown-cats.appspot.com",
    messagingSenderId: "135562927687",
    appId: "1:135562927687:web:5735c1ab0e9f6befc4db62",
    measurementId: "G-7XHYD11TXC"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
