<!-- src/components/CatProfile.vue -->
<template>
  <div class="cat-profiles grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8">
    <div
      v-for="cat in cats"
      :key="cat.id"
      @click="showModal(cat)"
      class="cat-card bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-transform hover:scale-105 hover:shadow-lg"
    >
      <img :src="cat.image_url" :alt="cat.name" class="cat-image rounded-lg mb-4 w-full h-48 object-cover" />
      <h2 class="text-2xl font-semibold mb-2 text-warm-brown">{{ cat.name }}</h2>
      <p class="text-base text-gray-dark mb-1"><strong>Age:</strong> {{ cat.age }}</p>
      <p class="text-base text-gray-dark"><strong>Breed:</strong> {{ cat.breed }}</p>
    </div>

    <!-- Modal with Transition -->
    <transition name="modal-pop">
      <div v-if="selectedCat" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-hidden px-4 py-9 md:px-8" @click.self="closeModal">
        <!-- Modal Content with Inline Padding and Margin -->
        <div class="modal-container relative bg-cream-light rounded-lg shadow-2xl w-full max-w-3xl h-full max-h-4xl overflow-y-auto" style="padding: 16px; max-height: 70vh;">
          <!-- Close Button with Absolute Position -->
          <button @click="closeModal" class="fixed top-2 right-2 text-gray-600 hover:text-gray-900 rounded-full bg-white shadow-md p-2" style="z-index: 10;">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div class="flex flex-col lg:flex-row lg:gap-8 items-start lg:items-start">
            <!-- Image Container -->
            <div class="w-full lg:w-1/2 flex-shrink-0 mb-4 lg:mb-0">
              <img :src="selectedCat.image_url" :alt="selectedCat.name" class="rounded-lg w-full object-cover h-64 lg:h-auto" />
              <!-- Button Centered Below Image on Larger Screens Only -->
              <div class="hidden lg:flex lg:justify-center mt-4">
                <a
                  :href="selectedCat.profile_link"
                  target="_blank"
                  class="inline-block bg-warm-button text-white font-medium py-2 px-6 rounded-lg hover:bg-warm-button-dark transition-colors"
                >
                  View Full Profile
                </a>
              </div>
            </div>
            <!-- Text Container -->
            <div class="lg:w-1/2 overflow-y-auto px-4 lg:px-0 flex-grow">
              <h2 class="text-3xl font-semibold mb-4 text-warm-brown">{{ selectedCat.name }}</h2>
              <div class="space-y-2">
                <p class="text-lg text-gray-dark"><strong>Age:</strong> {{ selectedCat.age }}</p>
                <p class="text-lg text-gray-dark"><strong>Breed:</strong> {{ selectedCat.breed }}</p>
                <p class="text-gray-dark"><strong>Description:</strong> {{ selectedCat.description }}</p>
                <p class="text-gray-dark"><strong>Compatibility with Kids:</strong> {{ selectedCat.compatibility.KIDS }}</p>
              </div>
              <!-- Button Inside Text Container on Mobile Only -->
              <div class="flex justify-center lg:hidden mt-6">
                <a
                  :href="selectedCat.profile_link"
                  target="_blank"
                  class="inline-block bg-warm-button text-white font-medium py-2 px-6 rounded-lg hover:bg-warm-button-dark transition-colors"
                >
                  View Full Profile
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export default {
  data() {
    return {
      cats: [],
      selectedCat: null
    };
  },
  async created() {
    const querySnapshot = await getDocs(collection(db, "cats"));
    this.cats = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },
  methods: {
    showModal(cat) {
      this.selectedCat = cat;
      document.body.style.overflow = 'hidden'; // Disable background scrolling
    },
    closeModal() {
      this.selectedCat = null;
      document.body.style.overflow = ''; // Re-enable background scrolling
    }
  }
};
</script>

<style scoped>
/* Background and button colors */
.bg-cream-light {
  background-color: #f6f1eb;
}
.text-warm-brown {
  color: #7a5230;
}
.text-gray-dark {
  color: #5f5f5f;
}
.bg-warm-button {
  background-color: #d04a02;
}
.bg-warm-button-dark {
  background-color: #a83802;
}

/* Close button with shadow */
button {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Modal pop effect */
.modal-pop-enter-active, .modal-pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.4s ease;
}
.modal-pop-enter, .modal-pop-leave-to {
  transform: scale(0.95) translateY(10px);
  opacity: 0;
}

/* Mobile margin for modal */
@media (max-width: 768px) {
  .modal-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
