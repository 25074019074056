<!-- src/components/FooterBottom.vue -->
<template>
    <footer class="bg-cream-light py-4 text-center">
      <p class="text-gray-dark font-medium">
        Made with ❤️ by 
        <a href="https://abduldavids.co.za" target="_blank" class="text-warm-brown hover:text-warm-brown-dark transition-colors">
          Abdul Davids
        </a>
      </p>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterBottom"
  };
  </script>
  
  <style scoped>
  /* Background and text colors */
  .bg-cream-light {
    background-color: #f6f1eb;
  }
  .text-gray-dark {
    color: #5f5f5f;
  }
  
  /* Brown color for the link */
  .text-warm-brown {
    color: #7a5230; /* Brown color */
  }
  
  /* Darker brown on hover */
  .hover\:text-warm-brown-dark:hover {
    color: #5a3a20; /* Darker brown */
  }
  </style>
  