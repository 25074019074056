<!-- src/App.vue -->
<template>
  <div class="bg-cream-light min-h-screen flex flex-col items-center font-serif">
    <!-- Header Component -->
    <HeaderTop />

    <!-- Main Content -->
    <CatProfile />

    <FooterBottom />

  </div>
</template>

<script>
import HeaderTop from "./components/HeaderTop.vue";
import CatProfile from "./components/CatProfile.vue";
import FooterBottom from "./components/FooterBottom.vue";


export default {
  components: {
    HeaderTop,
    CatProfile,
    FooterBottom
  }
};
</script>

<style>
/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');

body {
  font-family: "Playfair Display", serif;
  background-color: #f6f1eb;
  color: #3d3d3d;
}

.bg-cream-light {
  background-color: #f6f1eb; /* Soft, cream background */
}

/* Disable scrolling on the body */
.no-scroll {
  overflow: hidden;
}

</style>
