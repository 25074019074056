<!-- src/components/HeaderTop.vue -->
<template>
  <header class="bg-cream-light shadow-sm w-full py-4 px-6 flex flex-col md:flex-row items-center justify-between">
    <!-- Logo / Title -->
    <h1 class="text-4xl font-semibold text-warm-brown mb-4 md:mb-0 text-center md:text-left">Cats of Cape Town</h1>
    <!-- Navigation Links -->
    <nav class="flex space-x-8">
      <a href="https://x.com/catsofcapetown" target="_blank" class="text-gray-dark font-medium hover:text-warm-brown transition-colors">
        Twitter (It's called X now 🙄)
      </a>
    </nav>
  </header>
</template>


<script>
export default {
  name: "HeaderTop"
};
</script>

<style scoped>
.bg-cream-light {
  background-color: #f6f1eb;
}
.text-warm-brown {
  color: #7a5230;
}
.text-gray-dark {
  color: #5f5f5f;
}
.hover\:text-warm-brown:hover {
  color: #7a5230;
}
.transition-colors {
  transition: color 0.3s ease;
}
</style>
